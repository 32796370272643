import React from 'react'

import ChevronLink from 'src/components/UI/ChevronLink'

import Plus from 'src/assets/img/HeroIcons/Plus.png'
import Book from 'src/assets/img/HeroIcons/Book.png'
import Share from 'src/assets/img/HeroIcons/Share.png'
import RoundPlus from 'src/assets/img/HeroIcons/RoundPlus.png'

import { Container, Content, Icons, Icon } from './styles'

const Hero = ({
  title,
  subtitle,
  text,
  showIcons = false,
  bgCurves = null,
  bgCurvesMobile = null,
  bgCurvesWide = null,
  textColor = null,
  chevronColor = null
}) => {
  return (
    <Container
      bgCurves={bgCurves}
      bgCurvesMobile={bgCurvesMobile}
      bgCurvesWide={bgCurvesWide}
      textColor={textColor}
      id="hero">
      <Content>
        <div className="text">
          <h1>{title}</h1>
          <h2>{subtitle}</h2>

          <h3>{text}</h3>

          <ChevronLink color={chevronColor}>Saiba Mais</ChevronLink>
        </div>
      </Content>

      {showIcons && (
        <Icons>
          <li>
            <Icon src={Plus} />
          </li>
          <li>
            <Icon src={Book} />
          </li>
          <li>
            <Icon src={Share} />
          </li>
          <li>
            <Icon src={RoundPlus} />
          </li>
        </Icons>
      )}
    </Container>
  )
}

export default Hero
