import styled from 'styled-components'
import { MdChevronRight } from 'react-icons/md'

export const Container = styled.a`
  display: flex;
  align-items: center;
  color: var(--secondary);
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  transition: 0.4s all ease-in-out;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  span {
    color: ${(props) => props.color || 'var(--secondary)'};
    transition: 0.4s all ease-out;
    transition-delay: 100ms;
  }

  div.chevron {
    display: flex;
    align-items: center;
    padding: 12px;
    background-color: ${(props) => props.color || 'var(--secondary)'};
    padding: 0;
    border-radius: 100%;
    margin-right: 10px;
  }

  div.center {
    display: flex;
    align-items: center;
    color: var(--secondary);
  }

  &:hover {
    span {
      transform: scale(1.02);
    }

    .icon {
      transform: translateX(3px);
    }
  }
`

export const Chevron = styled(MdChevronRight)`
  width: 1.8rem;
  height: 1.8rem;
  fill: ${(props) => (props.color === '#fff' ? 'var(--primary)' : '#fff')};
  transition: 0.4s all ease-in-out;
`
