import styled from 'styled-components'

import curves from 'src/assets/img/Hero/Curves.webp'
import curvesMobile from 'src/assets/img/Hero/CurvesMobile.webp'
import curvesWide from 'src/assets/img/Hero/CurvesWide.webp'

export const Container = styled.div`
  width: 100%;
  min-height: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--primary);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;

  @media (max-width: 768px) {
    background-image: url(${(props) => props.bgCurvesMobile || curvesMobile});
  }

  h1,
  h2,
  h3 {
    text-align: center;
  }

  h1 {
    color: ${(props) => props.textColor || 'var(--secondary)'};
    font-family: 'Gilroy-Bold';
    font-size: 3rem;
    margin-top: 50px;
    margin-bottom: 15px;

    @media (max-width: 561px) {
      line-height: 40px;
    }
  }

  h2 {
    color: ${(props) => props.textColor || '#fff'};
    font-family: 'Gilroy-Regular';
    font-size: 2.8rem;

    @media (max-width: 561px) {
      line-height: 40px;
    }
  }

  h3 {
    margin-top: 30px;
    max-width: 550px;
    color: ${(props) => props.textColor || '#fff'};
    font-family: 'Gilroy-Thin';
    font-size: 1.2rem;

    strong {
      font-family: 'Gilroy-Bold';
    }
  }

  @media (min-width: 768px) {
    align-items: flex-start;
    background-size: cover;
    background-repeat: no-repeat;

    h1,
    h2,
    h3 {
      text-align: start;
    }

    a.know-more {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media (min-width: 769px) {
    background-image: url(${(props) => props.bgCurves || curves});
  }

  @media (min-width: 1500px) {
    background-size: cover;
  }

  @media (min-width: 1840px) {
    background-image: url(${(props) => props.bgCurvesWide || curvesWide});
  }
`

export const Content = styled.div`
  max-width: 1200px;
  height: 100%;
  padding: 120px 40px;
  padding-bottom: 50px;
  z-index: 2;

  @media (min-width: 768px) {
    padding: 120px var(--padding-horizontal);
  }
`

export const Icons = styled.ul`
  width: 100%;
  min-width: 250px;
  max-width: 1000px;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  align-self: center;
  z-index: 3;

  @media (max-width: 1500px) {
    margin-top: 30px;
  }

  @media (min-width: 768px) {
    align-items: flex-start;
    margin-top: 120px;
  }

  li {
    height: 7em;
    width: 7em;
    padding: 20px;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: var(--background);
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    transition: 0.3s all ease-out;

    &:hover {
      transform: translateY(-8px);
    }
  }
`

export const Icon = styled.img`
  width: 100%;
`
