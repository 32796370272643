import React from 'react'

import { Container, Chevron } from './styles'

const ChevronLink = ({ href = '#', children, color }) => {
  return (
    <Container href={href} color={color}>
      <div className="center">
        <div className="chevron">
          <Chevron color={color} className="icon" />
        </div>
        <span>{children}</span>
      </div>
    </Container>
  )
}

export default ChevronLink
